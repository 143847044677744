<template>
    <div>
      <div class="main">
        <el-tabs v-model="editableTabsValue" @tab-click="handleClick">
          <el-tab-pane :label="item.name" :name="item.no.toString()" v-for="(item,index) in typeList" :key="index">

            <div class="homeBusiness">
              <div class="homeBusiness-bottom">
                <div class="homeBusiness-bottom-list">
                  <div class="homeBusiness-bottom-list-item" v-for="(item,index) in businessContentList" :key="index" @click="toKnowledgeDetail(item.no)">
                    <img :src="item.picUrl" alt="">
                    <div class="homeBusiness-bottom-list-item-bottom">
                      <p>{{ item.title.length > 11 ? item.title.substring(0,11)+'...' : item.title }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
</template>

<script>
  import { knowledgeType, knowledge } from '@/api/shupetsApi'
  export default {
    data() {
      return {
        editableTabsValue: 0,
        typeList: [
          // {name: '新手指南', no: 1},
        ],

        businessContentList: [
          // {
          //   title: '',
          //   picUrl: "",
          // },
        ],
      }
    },
    async created() {
      await this.knowledgeType()
      this.businessContentList = []
      if(this.typeList.length != 0) {
        await this.knowledge(this.typeList[0].no)
      }
    },
    methods: {
      async knowledgeType(){
        await knowledgeType({pageNo: 1, pageSize: 100}).then(res=>{
          this.typeList=res.data.records
        })
      },
      async knowledge(_typeNo) {
        await knowledge({
          keywords: "",
          pageNo: 1,
          pageSize: 100,
          typeNo: _typeNo
        }).then(res=>{
          this.businessContentList = res.data.records
        })
      },
      async handleClick(tab, event) {
        this.businessContentList = []
        await this.knowledge(tab.index)
      },
      toKnowledgeDetail(_no) {
        this.$router.push({
          name: 'KnowledgeDetail',
          query: {no: _no}
        })
      },

    }
  }
</script>

<style lang="scss" scoped>
    img[lazy="loading"] {
        width: 400px !important;
        height: 50px !important;
    }
    /deep/ .el-carousel__container {
        height: 100% !important;
    }

    .main {
      width: 80%; margin: 0 auto;
    }

    .knowledge-left {
      float: left;
      img {
        width: 200px;
      }
    }

    .knowledge-right {
      float: left;
    }

    /deep/ .el-tabs__nav-wrap::after {
      background-color: #fff;
    }

    /deep/ .el-tabs__active-bar {
      background-color: #fff;
    }

    /deep/ .el-tabs__item {
      font-size: 0.5rem;
      color: gray;
      padding: 0 0.5rem;
    }

    /deep/ .el-tabs__item.is-active {
      color: #000;
    }


    
  .homeBusiness {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .homeBusiness-bottom {
      box-sizing: border-box;

      .homeBusiness-bottom-list {
        overflow: hidden;
      }
      
      .homeBusiness-bottom-list-item {
        width: 169px;

        float: left;

        margin-bottom: 7px;
        margin-right: 7px;
        border-radius: 3px 3px 3px 3px;

        img {width: 100%;}

        .homeBusiness-bottom-list-item-top {
          height: 104px;
        }

        .homeBusiness-bottom-list-item-bottom {
          height: 50px;
          background: #F4F4F4;
          P:first-child {
            font-size: 10px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #989898;
            padding-left: 29px;
            padding-top: 17px;
          }
        }
      }
    }
  }
</style>